import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchConsultants, fetchAbsencesForConsultant, fetchHolidays, getNumberOfWeeksInYear, getAbsenceCellColor, getCurrentWeek } from './Utils/Utils';
import './Style/Heatmap.css';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const prepareWeeklyAbsenceData = (absences, holidays, year) => {
    const numWeeks = getNumberOfWeeksInYear(year);
    let weeklyHours = Array.from({ length: numWeeks }, () => ({ allocated: 0, maxAvailable: 40 }));

    absences.forEach(absence => {
        if (absence.year === year) {
            const weekIndex = Math.min(absence.week_of_year - 1, numWeeks - 1);
            weeklyHours[weekIndex].allocated += absence.hours;
        }
    });

    holidays.forEach(holiday => {
        const holidayYear = new Date(holiday.date).getFullYear();
        if (holidayYear === year) {
            const weekIndex = Math.min(holiday.week_of_year - 1, numWeeks - 1);
            weeklyHours[weekIndex].allocated += 8;
        }
    });

    return weeklyHours;
};

export default function AbsenceHeatmap({ year }) {
    const [consultants, setConsultants] = useState([]);
    const [weeklyData, setWeeklyData] = useState({});
    const [totalAbsenceHours, setTotalAbsenceHours] = useState({ next4Weeks: 0, next12Weeks: 0 });
    const navigate = useNavigate();
    const heatmapContainerRef = useRef(null); // Reference for the heatmap container

    useEffect(() => {
        async function loadData() {
            const fetchedConsultants = await fetchConsultants();
            const holidays = await fetchHolidays();
            setConsultants(fetchedConsultants);
    
            const allConsultantData = {};
            for (const consultant of fetchedConsultants) {
                const absences = await fetchAbsencesForConsultant(consultant.consultant_id);
                const data = prepareWeeklyAbsenceData(absences, holidays, year);
                allConsultantData[consultant.consultant_id] = data;
            }
    
            setWeeklyData(allConsultantData);
        }
    
        async function fetchTotalAbsenceHours() {
            try {
                const absencesResponse = await fetch(`${API_BASE_URL}/api/absences/with-future-hours`, {
                    method: 'GET',
                    credentials: 'include',
                });
                const holidaysResponse = await fetch(`${API_BASE_URL}/api/holidays/with-future-weeks`, {
                    method: 'GET',
                    credentials: 'include',
                });
    
                const absencesData = await absencesResponse.json();
                const holidaysData = await holidaysResponse.json();
    
                const hoursNext4WeeksAbsences = Number(absencesData.hours_next_4_weeks);
                const hoursNext4WeeksHolidays = Number(holidaysData.holidaysWithFutureWeeks[0].hours_next_4_weeks);
                const hoursNext12WeeksAbsences = Number(absencesData.hours_next_12_weeks);
                const hoursNext12WeeksHolidays = Number(holidaysData.holidaysWithFutureWeeks[0].hours_next_12_weeks);
    
                if (
                    isNaN(hoursNext4WeeksAbsences) ||
                    isNaN(hoursNext4WeeksHolidays) ||
                    isNaN(hoursNext12WeeksAbsences) ||
                    isNaN(hoursNext12WeeksHolidays)
                ) {
                    console.error('One of the values is not a number');
                    return;
                }
    
                const totalNext4Weeks = hoursNext4WeeksAbsences + hoursNext4WeeksHolidays;
                const totalNext12Weeks = hoursNext12WeeksAbsences + hoursNext12WeeksHolidays;
    
                setTotalAbsenceHours({ next4Weeks: totalNext4Weeks, next12Weeks: totalNext12Weeks });
            } catch (error) {
                console.error('Error fetching total absence hours:', error);
            }
        }
    
        loadData();
        fetchTotalAbsenceHours();

        // Scroll handling after data is loaded
        const currentWeek = getCurrentWeek();
        if (heatmapContainerRef.current) {
            if (currentWeek >= 1 && currentWeek <= 20) {
                heatmapContainerRef.current.scrollLeft = 0; // No scroll
            } else if (currentWeek > 20 && currentWeek <= 33) {
                heatmapContainerRef.current.scrollLeft = heatmapContainerRef.current.scrollWidth / 3; // Scroll to the middle
            } else if (currentWeek > 33 && currentWeek <= 53) {
                heatmapContainerRef.current.scrollLeft = heatmapContainerRef.current.scrollWidth; // Scroll all the way to the right
            }
        }
    }, [year]);

    const numWeeks = getNumberOfWeeksInYear(year);

    const handleCellClick = (consultantId, weekNumber) => {
        navigate(`/absence-details/${consultantId}/${year}/${weekNumber}`);
    };

    return (
        <>
            <div className="total-absence-summary-container">
                <div className="total-absence-summary">
                    <p>Kommande 4 veckor: {totalAbsenceHours.next4Weeks} h</p>
                    <p>Kommande 12 veckor: {totalAbsenceHours.next12Weeks} h</p>
                </div>
            </div>
        <div className="heatmap-wrapper">
            <div className="heatmap-container" ref={heatmapContainerRef} style={{ overflowX: 'auto' }}>
                <div className="week-labels">
                    <div className="consultant-name name-color">Vecka</div>
                    {Array.from({ length: numWeeks }).map((_, index) => (
                        <div key={index} className="week-label">{index + 1}</div>
                    ))}
                </div>
                {consultants.map(consultant => (
                    <div key={consultant.consultant_id} className="consultant-row">
                        <div className="consultant-name name-color">{consultant.consultant_name}</div>
                        {weeklyData[consultant.consultant_id]?.map((week, index) => (
                            <div
                                key={index}
                                className="heatmap-cell hover"
                                style={{ backgroundColor: getAbsenceCellColor(week.allocated, week.maxAvailable) }}
                                onClick={() => handleCellClick(consultant.consultant_id, index + 1)}
                            >
                                {week.allocated}
                            </div>
                        ))}
                    </div>
                ))}
            </div>
        </div>
        </>
    );
}
