const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

// Fetch all consultants
export const fetchConsultants = async () => {
    const response = await fetch(`${API_BASE_URL}/api/consultants`, {credentials: 'include'});
    return response.json().then(data => data.consultants || []);
};

// Fetch all allocations
export const fetchAllocations = async (consultantId, year) => {
    const response = await fetch(`${API_BASE_URL}/api/allocations?consultant_id=${consultantId}&year=${year}`);
    return response.json().then(data => data.allocations || []);
};

// Fetch absence data for a specific consultant
export const fetchAbsencesForConsultant = async (consultantId) => {
    const response = await fetch(`${API_BASE_URL}/api/absence?consultant_id=${consultantId}`, {credentials: 'include'});
    const data = await response.json();
    return data.absence || [];
};

export const fetchAllAbsences = async (year) => {
    const url = `${API_BASE_URL}/api/all-absences?year=${year}`;
    const response = await fetch(url, { credentials: 'include' });
    const data = await response.json();
    return data.weeklyAbsences || [];
};

export const fetchAbsenceSummary = async (year) => {
    const response = await fetch(`${API_BASE_URL}/api/absence-summary?year=${year}`, {credentials: 'include'});
    const data = await response.json();
    return data.absenceSummary || [];
};

// Fetch all holidays that are observed on weekdays
export const fetchHolidays = async () => {
    const response = await fetch(`${API_BASE_URL}/api/holidays`, {credentials: 'include'});
    const data = await response.json();
    return data.holidays || [];
};

// Fetch absence hours for the next 4 and 12 weeks into the future
export const fetchAbsencesWithFutureHours = async () => {
    const response = await fetch(`${API_BASE_URL}/api/absences/with-future-hours`, {credentials: 'include'});
    return response.json().then(data => data.absences || []);
};

// Fetch holidays within the next 4 and 12 weeks
export const fetchHolidaysWithFutureWeeks = async () => {
    const response = await fetch(`${API_BASE_URL}/api/holidays/with-future-weeks`, {credentials: 'include'});
    return response.json().then(data => data.holidaysWithFutureWeeks || []);
};

export const getNumberOfWeeksInYear = (year) => {
    const startDate = new Date(year, 0, 1); // January 1st
    const endDate = new Date(year, 11, 31); // December 31st
    const daysInYear = (endDate - startDate) / (1000 * 3600 * 24) + 1;
    let numWeeks = Math.floor(daysInYear / 7);
    if (startDate.getDay() > 0 && endDate.getDay() < 6) numWeeks++; // Adjust for partial weeks
    return numWeeks;
};

// Get color for cells in AbsenceHeatmap
export const getAbsenceCellColor = (hours, maxAvailable = 40) => {
    if (maxAvailable === 0) return '#c92a2a';
    const percentage = (hours / maxAvailable) * 100;
    if (percentage >= 85) return '#c92a2a';
    else if (percentage >= 75) return '#f03e3e';
    else if (percentage >= 50) return '#ff6b6b';
    else if (percentage >= 25) return '#ff8787';
    else if (percentage >= 1) return '#ffc9c9';
    else return '#fff5f5';
};

// getBarColor using percentage of available hours to get color
export const getBarColor = (hours, maxAvailable = 40) => {
    if (maxAvailable === 0) return '#8B0000'; // Dark red for no available hours
    const percentage = (hours / maxAvailable) * 100;
    if (percentage >= 100) return '#8B0000'
    if (percentage >= 85) return '#ff0000'; // Red for 85% and above
    else if (percentage >= 75) return '#ffa500'; // Orange for 75% to 84%
    else if (percentage >= 50) return '#FFD700'; // Gold for 50% to 74%
    else if (percentage >= 25) return '#cccc00'; // Yellow for 25% to 49%
    else if (percentage >= 1) return '#339966'; // Green for 1% to 24%
    else return '#008000'; // Dark green for 0%
};


export const prepareWeeklyData = (allocations, absences, holidays, year) => {
    const numWeeks = getNumberOfWeeksInYear(year);
    let weeklyHours = Array.from({ length: numWeeks }, () => ({ allocated: 0, maxAvailable: 40 }));

    absences.forEach(absence => {
        if (absence.year === year) {
            const weekIndex = Math.min(absence.week_of_year - 1, numWeeks - 1);
            weeklyHours[weekIndex].maxAvailable -= absence.hours;
        }
    });
    holidays.forEach(holiday => {
        const weekIndex = Math.min(holiday.week_of_year - 1, numWeeks - 1);
        weeklyHours[weekIndex].maxAvailable -= 8;
    });
    allocations.forEach(allocation => {
        if (allocation.year === year) {
            const weekIndex = Math.min(allocation.week_of_year - 1, numWeeks - 1);
            weeklyHours[weekIndex].allocated += allocation.hours_allocated;
        }
    });

    return weeklyHours;
};

export function getCurrentWeek() {
    const today = new Date();
    const firstDayOfYear = new Date(today.getFullYear(), 0, 1);
    const pastDaysOfYear = (today - firstDayOfYear) / 86400000;
    return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
}

