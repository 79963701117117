import React, { useState, useEffect } from 'react';
import { Pie } from 'react-chartjs-2';
import { Chart, ArcElement, Tooltip, Legend } from 'chart.js';
import AbsenceHeatmap from './AbsenceHeatmap';
import { fetchConsultants, fetchAbsencesForConsultant, fetchAllAbsences, fetchAbsenceSummary, fetchHolidays } from './Utils/Utils';
import './Style/Absence.css';

// Register the necessary Chart.js components
Chart.register(ArcElement, Tooltip, Legend);

const absenceColors = {
    "Föräldraledighet": '#36A2EB',
    "Semester": '#FF6384',
    "Annat": '#FFCE56',
    "Unknown": '#CCCCCC',
    "Röd dag": '#c92a2a' // New color for holidays
};

const processConsultantAbsences = (absences) => {
    const types = {};
    absences.forEach(absence => {
        const type = absence.absence_type || 'Unknown';
        const hours = parseInt(absence.hours, 10);
        types[type] = (types[type] || 0) + hours;
    });
    return types;
};

export default function ConsultantAbsenceViewer() {
    const [consultants, setConsultants] = useState([]);
    const [selectedConsultant, setSelectedConsultant] = useState('all');
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
    const [viewType, setViewType] = useState('heatmap'); // Initial view set to 'heatmap'
    const [absenceData, setAbsenceData] = useState({ totalDays: 0, weeklyAbsence: {} });
    const [absenceTypes, setAbsenceTypes] = useState({});
    const [holidaysByWeek, setHolidaysByWeek] = useState({});
    const [totalHolidays, setTotalHolidays] = useState(0);
    
    useEffect(() => {
        fetchConsultants().then(data => setConsultants([{ consultant_id: 'all', consultant_name: 'Alla Konsulter' }, ...data]));
    }, []);

    useEffect(() => {
        fetchHolidays().then(holidays => {
            const holidaysByWeek = holidays.reduce((acc, holiday) => {
                if (new Date(holiday.date).getFullYear() === selectedYear) {
                    const weekNumber = holiday.week_of_year;
                    acc[weekNumber] = (acc[weekNumber] || 0) + 1;
                }
                return acc;
            }, {});
            setHolidaysByWeek(holidaysByWeek);

            const totalHolidaysObserved = holidays.filter(holiday => new Date(holiday.date).getFullYear() === selectedYear).length;
            setTotalHolidays(totalHolidaysObserved);
        });
    }, [selectedYear]); // Fetch holidays whenever the year changes

    useEffect(() => {
        const processAbsences = (absences) => {
            let totalHours = 0;
            let weeklyHours = {};

            absences.forEach(absence => {
                const hours = parseInt(absence.total_hours || absence.hours, 10);
                const weekIdentifier = `${absence.week_of_year}`;
                weeklyHours[weekIdentifier] = (weeklyHours[weekIdentifier] || 0) + hours;
                totalHours += hours;
            });

            const totalDays = (totalHours / 8).toFixed(2);
            setAbsenceData({ totalDays: Number(totalDays), weeklyAbsence: weeklyHours });
        };

        setAbsenceTypes({}); // Clear the types state before fetching new data

        if (selectedConsultant === 'all') {
            fetchAllAbsences(selectedYear).then(processAbsences);
            fetchAbsenceSummary(selectedYear).then(summary => {
                const types = {};
                summary.forEach(absence => {
                    types[absence.absence_type] = (types[absence.absence_type] || 0) + parseInt(absence.total_hours, 10);
                });
                setAbsenceTypes(types);
            });
        } else {
            fetchAbsencesForConsultant(selectedConsultant).then(absences => {
                const yearFilteredAbsences = absences.filter(absence => absence.year === selectedYear);
                processAbsences(yearFilteredAbsences);
                setAbsenceTypes(processConsultantAbsences(yearFilteredAbsences));
            });
        }
    }, [selectedConsultant, selectedYear]);

    const handleViewChange = () => {
        if (viewType === 'table') {
            setSelectedConsultant('all'); // Reset to 'all' when switching to heatmap
            setViewType('heatmap');
        } else {
            setViewType('table');
        }
    };

    const holidayHours = totalHolidays * 8 * (selectedConsultant === 'all' ? (consultants.length - 1) : 1);

    // Ensure "Röd dag" is included in the absenceTypes if holiday hours are present
    const updatedAbsenceTypes = { ...absenceTypes };
    if (holidayHours > 0) {
        updatedAbsenceTypes["Röd dag"] = holidayHours;
    }

    const chartData = {
        labels: Object.keys(updatedAbsenceTypes),
        datasets: [
            {
                label: 'Timmar',
                data: Object.values(updatedAbsenceTypes),
                backgroundColor: Object.keys(updatedAbsenceTypes).map(type => absenceColors[type] || absenceColors["Unknown"]),
                hoverBackgroundColor: Object.keys(updatedAbsenceTypes).map(type => absenceColors[type] || absenceColors["Unknown"])
            }
        ]
    };

    const totalAbsenceDays = selectedConsultant === 'all' 
        ? absenceData.totalDays + (totalHolidays * (consultants.length - 1)) 
        : absenceData.totalDays + totalHolidays;

    const getWeekHours = (week) => {
        const holidayHours = holidaysByWeek[week] ? holidaysByWeek[week] * 8 : 0;
        const absenceHours = absenceData.weeklyAbsence[week] || 0;
        if (selectedConsultant === 'all') {
            return absenceHours + holidayHours * (consultants.length - 1);
        }
        return absenceHours + holidayHours;
    }

    const allWeeks = [...new Set([
        ...Object.keys(absenceData.weeklyAbsence), 
        ...Object.keys(holidaysByWeek)
    ])].sort((a, b) => a - b); // Sort weeks numerically

    return (
        <div className='absence-header'>
            <button onClick={handleViewChange} className="button-37 swap">
                {viewType === 'table' ? 'Heatmap' : 'Tabell/Cirkeldiagram'}
            </button>
            <br></br>
            <select onChange={e => setSelectedYear(parseInt(e.target.value, 10))} value={selectedYear}>
                <option value="2023">2023</option>
                <option value="2024">2024</option>
                <option value="2025">2025</option>
            </select>
            {viewType === 'table' && (
                <select onChange={e => setSelectedConsultant(e.target.value)} value={selectedConsultant}>
                    {consultants.map(consultant => (
                        <option key={consultant.consultant_id} value={consultant.consultant_id}>
                            {consultant.consultant_name}
                        </option>
                    ))}
                </select>
            )}
            <div>Totalt antal dagar frånvaro: {totalAbsenceDays}</div>
            {viewType === 'table' ? 
                <div className="dashboard">
                    <table id="absenceTable">
                        <thead>
                            <tr>
                                <th>Vecka</th>
                                <th>TOT. h</th>
                            </tr>
                        </thead>
                        <tbody>
                            {allWeeks.map(week => {
                                const hours = getWeekHours(week);
                                if (hours > 0) { // Only display weeks with absences or holidays
                                    return (
                                        <tr key={week}>
                                            <td>{week}</td>
                                            <td>{hours}</td>
                                        </tr>
                                    );
                                }
                                return null;
                            })}
                        </tbody>
                    </table>
                    <div id="piechart">
                        {Object.keys(updatedAbsenceTypes).length > 0 && <Pie data={chartData} />}
                    </div>
                </div> 
                : <AbsenceHeatmap year={selectedYear} />
            }
        </div>
    );
}
