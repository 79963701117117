import React, { useState, useEffect, useCallback, useRef } from 'react';
import { fetchAbsencesForConsultant, fetchHolidays, getNumberOfWeeksInYear, getBarColor, getCurrentWeek } from './Utils/Utils';
import { prepareWeeklyData } from './ConsultantCard';
import './Style/Heatmap.css';

export default function ConsultantHeatmap({ consultants, year }) {
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
    const [weeklyData, setWeeklyData] = useState({});
    const [weeklySums, setWeeklySums] = useState([]);
    const heatmapContainerRef = useRef(null); // Reference for the heatmap container

    const calculateWeeklySums = useCallback((allConsultantData) => {
        const numWeeks = getNumberOfWeeksInYear(selectedYear);
        const sums = Array(numWeeks).fill(0);

        Object.values(allConsultantData).forEach(consultantData => {
            consultantData.forEach((week, index) => {
                sums[index] += week.allocated;
            });
        });

        setWeeklySums(sums);
    }, [selectedYear]);

    useEffect(() => {
        async function loadData() {
            const holidays = await fetchHolidays();
            const allConsultantData = {};

            for (const consultant of consultants) {
                const absences = await fetchAbsencesForConsultant(consultant.consultant_id);
                const data = prepareWeeklyData(consultant.allocations, absences, holidays, selectedYear);
                allConsultantData[consultant.consultant_id] = data;
            }

            setWeeklyData(allConsultantData);
            calculateWeeklySums(allConsultantData);

            // Scroll handling after data is loaded
            const currentWeek = getCurrentWeek();
            if (heatmapContainerRef.current) {
                if (currentWeek >= 1 && currentWeek <= 20) {
                    heatmapContainerRef.current.scrollLeft = 0; // No scroll
                } else if (currentWeek > 20 && currentWeek <= 33) {
                    heatmapContainerRef.current.scrollLeft = heatmapContainerRef.current.scrollWidth / 3; // Scroll to the middle
                } else if (currentWeek > 33 && currentWeek <= 53) {
                    heatmapContainerRef.current.scrollLeft = heatmapContainerRef.current.scrollWidth; // Scroll all the way to the right
                }
            }
        }

        loadData();
    }, [consultants, selectedYear, calculateWeeklySums]);

    const numWeeks = getNumberOfWeeksInYear(selectedYear); // Dynamically get number of weeks based on the selected year

    return (
        <>
            <div className="year-selector-container">
                <select value={selectedYear} onChange={(e) => setSelectedYear(parseInt(e.target.value, 10))}>
                    <option value="2023">2023</option>
                    <option value="2024">2024</option>
                    <option value="2025">2025</option>
                </select>
            </div>
            <div className="heatmap-wrapper">
                <div className="heatmap-container" ref={heatmapContainerRef} style={{ overflowX: 'auto' }}>
                    <div className="week-labels">
                        <div className="consultant-name">Vecka</div>
                        {Array.from({ length: numWeeks }).map((_, index) => (
                            <div key={index} className="week-label">{index + 1}</div>
                        ))}
                    </div>
                    {consultants.map(consultant => (
                        <div key={consultant.consultant_id} className="consultant-row">
                            <div className="consultant-name">{consultant.consultant_name}</div>
                            {weeklyData[consultant.consultant_id]?.map((week, index) => (
                                <div key={index} className="heatmap-cell" style={{ backgroundColor: getBarColor(week.allocated, week.maxAvailable) }}>
                                    {week.allocated}
                                </div>
                            ))}
                        </div>
                    ))}
                    <div className="consultant-row">
                        <div className="consultant-name">Sum</div>
                        {weeklySums.map((sum, index) => (
                            <div key={index} className="heatmap-cell">
                                {sum}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
}
