import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import './Style/Customer.css';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

async function toggleCustomerActive(customerId, isActive) {
    try {
        const response = await fetch(`${API_BASE_URL}/api/customers/${customerId}/activate`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ isActive }),
            credentials: 'include',
        });
        if (!response.ok) {
            throw new Error('Failed to update customer active status.');
        }
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error updating customer active status:', error);
        throw error;
    }
}

export default function Customer() {
    const [customersWithHours, setCustomersWithHours] = useState([]);
    const [originalData, setOriginalData] = useState([]); // Store the original data
    const [filterStatus, setFilterStatus] = useState('active'); // Default to active
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [sortConfig, setSortConfig] = useState({ key: null, direction: null }); // Default to no sorting
    const navigate = useNavigate();

    useEffect(() => {
        const fetchCustomers = async () => {
            setLoading(true);
            try {
                const responseHours = await fetch(`${API_BASE_URL}/api/customers/with-hours`, { credentials: 'include' });
                const responseFutureHours = await fetch(`${API_BASE_URL}/api/customers/with-future-hours`, { credentials: 'include' });

                if (!responseHours.ok || !responseFutureHours.ok) {
                    throw new Error('Failed to fetch customers');
                }

                const dataHours = await responseHours.json();
                const dataFutureHours = await responseFutureHours.json();
                const futureHoursMap = new Map(dataFutureHours.customersWithFutureHours.map(item => [item.customer_id, item]));

                const mergedData = dataHours.customersWithHours.map(item => ({
                    ...item,
                    ...futureHoursMap.get(item.customer_id)
                }));

                setCustomersWithHours(mergedData);
                setOriginalData(mergedData); // Store the original data
            } catch (error) {
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };
        fetchCustomers();
    }, []);

    const handleToggleActive = async (customerId, currentStatus) => {
        try {
            // Immediately update the UI state
            setCustomersWithHours(customers => 
                customers.map(customer =>
                    customer.customer_id === customerId ? { ...customer, is_active: !currentStatus } : customer
                )
            );
    
            // Send the update to the server
            const newStatus = !currentStatus;
            await toggleCustomerActive(customerId, newStatus);
    
            const responseHours = await fetch(`${API_BASE_URL}/api/customers/with-hours`, { credentials: 'include' });
            const responseFutureHours = await fetch(`${API_BASE_URL}/api/customers/with-future-hours`, { credentials: 'include' });
    
            if (responseHours.ok && responseFutureHours.ok) {
                const dataHours = await responseHours.json();
                const dataFutureHours = await responseFutureHours.json();
                const futureHoursMap = new Map(dataFutureHours.customersWithFutureHours.map(item => [item.customer_id, item]));
    
                const mergedData = dataHours.customersWithHours.map(item => ({
                    ...item,
                    ...futureHoursMap.get(item.customer_id)
                }));
    
                setCustomersWithHours(mergedData);
                setOriginalData(mergedData);
            }
        } catch (error) {
            setError(`Error updating customer: ${error.message}`);
            // Revert the UI state if the API call fails
            setCustomersWithHours(customers => 
                customers.map(customer =>
                    customer.customer_id === customerId ? { ...customer, is_active: currentStatus } : customer
                )
            );
        }
    };
    

    const handleFilterChange = (event) => {
        setFilterStatus(event.target.value);
    };

    const handleSort = (key) => {
        let direction = 'desc';
        if (sortConfig.key === key && sortConfig.direction === 'desc') {
            direction = null; // Reset to default when toggling off
        }
        setSortConfig({ key, direction });
    };

    const sortedCustomers = React.useMemo(() => {
        if (!sortConfig.key || !sortConfig.direction) {
            return [...originalData]; // Return to the original order when sorting is reset
        }

        return [...customersWithHours].sort((a, b) => {
            const aValue = parseFloat(a[sortConfig.key]) || 0;
            const bValue = parseFloat(b[sortConfig.key]) || 0;

            if (sortConfig.direction === 'desc') {
                return bValue - aValue;
            }

            return 0;
        });
    }, [customersWithHours, sortConfig, originalData]);

    const filteredCustomers = sortedCustomers.filter(customer => {
        switch (filterStatus) {
            case 'active': return customer.is_active;
            case 'inactive': return !customer.is_active;
            case 'all': default: return true;
        }
    });

    if (loading) return;
    if (error) return <div>Error: {error}</div>;

    return (
        <div>
            <h1>Kunder</h1>
            <select value={filterStatus} onChange={handleFilterChange}>
                <option value="active">Aktiva</option>
                <option value="inactive">Inaktiva</option>
                <option value="all">Alla</option>
            </select>
            <table>
                <thead>
                    <tr>
                        <th>Kund ID</th>
                        <th>Kund</th>
                        <th className="sortable" onClick={() => handleSort('total_hours')}>TOT. h</th>
                        <th className="sortable" onClick={() => handleSort('hours_next_4_weeks')}>4.V Fram</th>
                        <th className="sortable" onClick={() => handleSort('hours_next_12_weeks')}>12.V Fram</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredCustomers.map(({ customer_id, customer_name, total_hours, hours_next_4_weeks, hours_next_12_weeks, is_active }) => (
                        <tr key={customer_id}>
                            <td>{customer_id}</td>
                            <td>{customer_name}</td>
                            <td>{total_hours}</td>
                            <td>{hours_next_4_weeks}</td>
                            <td>{hours_next_12_weeks}</td>
                            <td>
                                <button
                                    onClick={() => handleToggleActive(customer_id, is_active)}
                                    className={`active-inactive ${is_active ? 'inactive' : 'active'}`}
                                >
                                    {is_active ? 'Aktiv' : 'Inaktiv'}
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <br />
            <button onClick={() => navigate('/NewCustomer')} className="button-37">Ny Kund</button>
        </div>
    );
}
