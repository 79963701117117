import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import AllocationForm from './AllocationForm';
import { getNumberOfWeeksInYear } from './Utils/Utils';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export default function AllocationDetails() {
    const [allocations, setAllocations] = useState([]);
    const [consultantName, setConsultantName] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { consultantId, year, weekNumber } = useParams();
    const navigate = useNavigate();

    const maxWeeks = getNumberOfWeeksInYear(parseInt(year)); // Get the number of weeks in the given year

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${API_BASE_URL}/api/allocation-details/${consultantId}/${year}/${weekNumber}`, { credentials: 'include' });
                if (!response.ok) {
                    throw new Error(`HTTP status ${response.status}`);
                }
                const data = await response.json();
                setAllocations(data.allocations);
                
                if (data.allocations.length > 0) {
                    setConsultantName(data.allocations[0].consultant_name);  // Assuming all allocations share the same consultant
                }
                
                console.log("Fetched allocations:", data.allocations);
            } catch (error) {
                setError("Failed to fetch allocation details: " + error.message);
                console.error(error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [consultantId, year, weekNumber]);

    const handleHoursChange = (index, newHours) => {
        const updatedAllocations = [...allocations];
        updatedAllocations[index].hours_allocated = newHours;
        setAllocations(updatedAllocations);
    };

    const updateHours = async () => {
        try {
            const updates = allocations.map(allocation => ({
                allocation_id: allocation.allocation_id,
                hours_allocated: allocation.hours_allocated
            }));
            console.log("Sending updates:", JSON.stringify(updates));
            const response = await fetch(`${API_BASE_URL}/api/bulk-update-allocations`, {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(updates),
                credentials: 'include',
            });
            if (!response.ok) {
                throw new Error(`HTTP status ${response.status}`);
            }
            navigate('/'); // Navigate back to the previous page
        } catch (error) {
            alert("Failed to update hours: " + error.message);
            console.error("Detailed error:", error);
        }
    };

    const deleteAllocation = async (allocationId) => {
        if (!window.confirm("Bekräfta borttagning")) {
            return;
        }
        
        try {
            const response = await fetch(`${API_BASE_URL}/api/weekly_allocations/${allocationId}`, {
                method: 'DELETE',
                credentials: 'include',
            });
            if (!response.ok) {
                throw new Error(`HTTP status ${response.status}`);
            }
            setAllocations(allocations.filter(allocation => allocation.allocation_id !== allocationId));
        } catch (error) {
            alert("Failed to delete allocation: " + error.message);
            console.error("Detailed error:", error);
        }
    };

    const goToPreviousWeek = () => {
        const previousWeek = Math.max(1, parseInt(weekNumber) - 1); // Ensure the week number doesn't go below 1
        navigate(`/allocation-details/${consultantId}/${year}/${previousWeek}`);
    };

    const goToNextWeek = () => {
        const nextWeek = Math.min(maxWeeks, parseInt(weekNumber) + 1); // Use maxWeeks to limit the week number
        navigate(`/allocation-details/${consultantId}/${year}/${nextWeek}`);
    };

    const handleCancel = () => {
        navigate('/');
    };

    const copyAllocationsToWeek = async (targetWeek) => {
        try {
            // Validate the targetWeek
            if (!targetWeek || targetWeek <= 0 || targetWeek > maxWeeks) {
                throw new Error("Invalid target week.");
            }

            // Create a copy of each allocation with the new week
            const copiedAllocations = allocations.map(allocation => ({
                customer_id: allocation.customer_id,
                consultant_id: parseInt(consultantId, 10), // Use consultantId from URL
                week_of_year: targetWeek,
                year: parseInt(year, 10), // Ensure year is an integer
                hours_allocated: allocation.hours_allocated,
            }));

            // Log copied allocations for debugging
            console.log("Copied allocations:", copiedAllocations);

            // Loop through each copied allocation and send a POST request
            for (const allocation of copiedAllocations) {
                const response = await fetch(`${API_BASE_URL}/api/weekly_allocations`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(allocation),
                    credentials: 'include',
                });

                if (!response.ok) {
                    const errorText = await response.text();
                    throw new Error(`Failed to copy allocation: ${errorText}`);
                }
            }

            console.log('Allocations successfully copied.');
            navigate(`/allocation-details/${consultantId}/${year}/${targetWeek}`); // Navigate to the new week
        } catch (error) {
            alert(`Failed to copy allocations: ${error.message}`);
            console.error('Copy error:', error);
        }
    };

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;

    return (
        <div>
            <h1>Konsult: {consultantName}</h1>
            <h2>
                <button onClick={goToPreviousWeek} disabled={weekNumber <= 1}>&larr;</button> 
                Vecka {weekNumber}, {year} 
                <button onClick={goToNextWeek} disabled={weekNumber >= maxWeeks}>&rarr;</button>
            </h2>

            {allocations.length > 0 ? (
                <ul>
                    {allocations.map((allocation, index) => (
                        <div key={index}>
                            Kund: {allocation.customer_name} - Timmar:
                            <input
                                id="input-hours"
                                type="number"
                                value={allocation.hours_allocated}
                                onChange={(e) => handleHoursChange(index, e.target.value)}
                            />
                            <button onClick={() => deleteAllocation(allocation.allocation_id)} className="delete-btn">X</button>
                        </div>
                    ))}
                </ul>
            ) : (
                <p>Inga timmar planerade.</p>
            )}
            <button onClick={updateHours} className="submit-btn">Spara</button>
            <button onClick={handleCancel} className="submit-btn" id="cancel-button">Avbryt</button>
            
            <br />
            <br></br>
            <button 
                onClick={() => copyAllocationsToWeek(parseInt(weekNumber, 10) + 1)}
                className="copy-btn"
                disabled={parseInt(weekNumber, 10) >= maxWeeks}
            >
                Kopiera till nästa vecka
            </button>

            <br />
            <br />
            <h2>Ny Rapportering</h2>
            <div className="allocation-form-container">
                <AllocationForm consultantId={consultantId} />
            </div>
        </div>
    );
}
