import React, { useState, useEffect } from 'react';
import ConsultantCard from './ConsultantCard';
import ConsultantHeatmap from './ConsultantHeatmap';
import { useNavigate } from "react-router-dom";

import '../App.css'

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export default function Overview() {
    const navigate = useNavigate();
    const [consultants, setConsultants] = useState([]);
    const [selectedConsultantId, setSelectedConsultantId] = useState(() => {
        // Initialize selected consultant ID from sessionStorage or default to 'all'
        return sessionStorage.getItem('selectedConsultantId') || 'all';
    });
    const [viewType, setViewType] = useState('card');

    useEffect(() => {
        Promise.all([
            fetch(`${API_BASE_URL}/api/consultants`, { credentials: 'include' }).then(response => response.json()),
            fetch(`${API_BASE_URL}/api/allocations`, { credentials: 'include' }).then(response => response.json())
        ]).then(([consultantsData, allocationsData]) => {
            const consultantsWithAllocations = consultantsData.consultants.map(consultant => ({
                ...consultant,
                allocations: allocationsData.allocations.filter(allocation => allocation.consultant_id === consultant.consultant_id)
            }));
            setConsultants(consultantsWithAllocations);
        }).catch(error => console.error('Error fetching data:', error));
    }, []);

    const goToNewConsultant = () => {
        navigate('/NewConsultant');
    };

    const handleConsultantChange = (event) => {
        const newConsultantId = event.target.value;
        setSelectedConsultantId(newConsultantId);
        // Store selected consultant ID in sessionStorage
        sessionStorage.setItem('selectedConsultantId', newConsultantId);
    };

    const handleViewChange = () => {
        setViewType(viewType === 'card' ? 'heatmap' : 'card');
    };

    // Always use all consultants for the heatmap regardless of selection
    const filteredConsultants = viewType === 'heatmap' 
        ? consultants
        : selectedConsultantId === 'all'
            ? consultants
            : consultants.filter(consultant => consultant.consultant_id.toString() === selectedConsultantId);

    return (
        <div>
            <header className="App-header">
                <button onClick={handleViewChange} className="button-37 swap">
                    {viewType === 'card' ? 'Heatmap' : 'Kort'}
                </button>

                {viewType === 'card' ?
                    <>
                        <select value={selectedConsultantId} onChange={handleConsultantChange}>
                            <option value="all">Alla Konsulter</option>
                            {consultants.map(consultant => (
                                <option key={consultant.consultant_id} value={consultant.consultant_id}>
                                    {consultant.consultant_name}
                                </option>
                            ))}
                        </select>
                        {filteredConsultants.map(consultant => (
                            <ConsultantCard
                                key={consultant.consultant_id}
                                consultant={consultant}
                                allocations={consultant.allocations}
                            />)
                        )}
                        <button onClick={goToNewConsultant} className="button-37">Ny Konsult</button>
                    </>
                    :
                    <ConsultantHeatmap consultants={filteredConsultants} />
                }
            </header>
        </div>
    );
}
