import React, { useState, useEffect } from 'react';
import { Doughnut } from 'react-chartjs-2';
import 'chart.js/auto';
import './Style/Total.css';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

// Function to get the current week number
export const getCurrentWeek = () => {
    const now = new Date();
    const start = new Date(now.getFullYear(), 0, 1);
    const diff = now - start + (start.getTimezoneOffset() - now.getTimezoneOffset()) * 60000;
    const oneWeek = 604800000;
    return Math.floor(diff / oneWeek) + 1;
};

export default function Placeholder() {
    const currentYear = new Date().getFullYear();
    const currentWeek = getCurrentWeek();
    const defaultEndWeek = currentWeek + 3 > 52 ? 52 : currentWeek + 3;

    const [startWeek, setStartWeek] = useState(currentWeek);
    const [endWeek, setEndWeek] = useState(defaultEndWeek);
    const [year, setYear] = useState(currentYear);
    const [totalCapacity, setTotalCapacity] = useState([]);
    const [plannedWork, setPlannedWork] = useState([]);
    const [error, setError] = useState(null);

    const fetchTotalCapacity = async (startWeek, endWeek, year) => {
        try {
            const response = await fetch(`${API_BASE_URL}/api/total-capacity?startWeek=${startWeek}&endWeek=${endWeek}&year=${year}`, {credentials: 'include'});
            const result = await response.json();
            if (response.ok) {
                setTotalCapacity(result.data);
                setError(null);  // Clear any previous error
            } else {
                setError(result.error);
            }
        } catch (error) {
            setError(error.message);
        }
    };

    const fetchPlannedWork = async (startWeek, endWeek, year) => {
        try {
            const response = await fetch(`${API_BASE_URL}/api/planned-work?startWeek=${startWeek}&endWeek=${endWeek}&year=${year}`, {credentials: 'include'});
            const result = await response.json();
            if (response.ok) {
                setPlannedWork(result.data);
                setError(null);  // Clear any previous error
            } else {
                setError(result.error);
            }
        } catch (error) {
            setError(error.message);
        }
    };

    useEffect(() => {
        fetchTotalCapacity(startWeek, endWeek, year);
        fetchPlannedWork(startWeek, endWeek, year);
    }, [startWeek, endWeek, year]);

    // Aggregate data over the span
    const totalCapacitySum = totalCapacity.reduce((acc, week) => acc + Number(week.available_capacity), 0);
    const plannedWorkSum = plannedWork.reduce((acc, week) => acc + Number(week.total_planned_work || 0), 0);
    const remainingTimeSum = totalCapacitySum - plannedWorkSum;

    const beläggningPercentage = ((plannedWorkSum / totalCapacitySum) * 100).toFixed(2);
    const remainingPercentage = (100 - beläggningPercentage).toFixed(2);

    const chartData = {
        labels: ['Beläggning', 'Resterande'],
        datasets: [
            {
                data: [beläggningPercentage, remainingPercentage],
                backgroundColor: ['rgba(255, 99, 132, 0.6)', 'rgba(153, 102, 255, 0.6)'],
                hoverBackgroundColor: ['rgba(255, 99, 132, 1)', 'rgba(153, 102, 255, 1)'],
            }
        ],
    };

    const calculateRemainingTime = (capacity, work) => capacity - (work || 0);

    const calculatePercentage = (capacity, work) => {
        if (capacity === 0) return "0%";
        return Math.round((work / capacity) * 100) + "%";
    };

    return (
        <div>
            <h2>Tillgänglig mot Budgeterad tid</h2>
            <form className="form-container">
                <label>
                    Start Vecka:
                    <input
                        type="number"
                        value={startWeek}
                        onChange={(e) => setStartWeek(parseInt(e.target.value))}
                        min="1"
                        max="53"
                    />
                </label>
                <label>
                    T.O.M. Vecka:
                    <input
                        type="number"
                        value={endWeek}
                        onChange={(e) => setEndWeek(parseInt(e.target.value))}
                        min="1"
                        max="53"
                    />
                </label>
                <label>
                    År:
                    <input
                        type="number"
                        value={year}
                        onChange={(e) => setYear(parseInt(e.target.value))}
                    />
                </label>
            </form>
            {error && <div className="error-message">Error: {error}</div>}
            <div className="table-chart-container">
                <table className="fixed-table">
                    <thead>
                        <tr>
                            <th>Vecka</th>
                            <th>Tillgänglig tid</th>
                            <th>Budgeterad tid</th>
                            <th>Resterande</th>
                            <th>Procent</th>
                        </tr>
                    </thead>
                    <tbody>
                        {totalCapacity.map((week, index) => (
                            <tr key={week.week_of_year}>
                                <td>{week.week_of_year}</td>
                                <td>{week.available_capacity}</td>
                                <td>{plannedWork[index]?.total_planned_work || 0}</td>
                                <td>{calculateRemainingTime(week.available_capacity, plannedWork[index]?.total_planned_work)}</td>
                                <td>{calculatePercentage(week.available_capacity, plannedWork[index]?.total_planned_work || 0)}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <div className="chart-container">
                    <Doughnut data={chartData} />
                    <div className="chart-data">
                        <p>Beläggning (Budgeterad tid): {plannedWorkSum} timmar</p>
                        <p>Resterande: {remainingTimeSum} timmar</p>
                    </div>
                </div>
            </div>
        </div>
    );
}
