import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import './Style/NewAllocation.css';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

async function registerAbsence(absences) {
    console.log("Sending absences:", JSON.stringify(absences));
    try {
        const response = await fetch(`${API_BASE_URL}/api/absence`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(absences),
            credentials: 'include',
        });
        
        if (!response.ok) {
            const errorText = await response.text();
            console.error("Failed response text:", errorText);
            throw new Error(`Failed to communicate with the API: ${response.statusText}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error("Error in registerAbsence:", error);
        throw error;
    }
}

export default function NewAbsence() {
    const { consultantId } = useParams(); // This hooks extracts params from the URL
    const navigate = useNavigate();
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [holidays, setHolidays] = useState([]);
    const [absenceType, setAbsenceType] = useState('Semester');
    const [absences, setAbsences] = useState([]);
    const [totalHolidays, setTotalHolidays] = useState(0); // Store total holidays in the selected span
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    useEffect(() => {
        fetch(`${API_BASE_URL}/api/holidays`, {credentials: 'include'})
            .then(response => response.json())
            .then(data => setHolidays(data.holidays || []));
    }, []);

    const handleStartDateChange = (event) => {
        setStartDate(event.target.value);
    };

    const handleEndDateChange = (event) => {
        setEndDate(event.target.value);
    };

    const handleAbsenceTypeChange = (event) => {
        setAbsenceType(event.target.value);
    };

    const getWeekOfYear = (date) => {
        const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
        const pastDaysOfYear = (date - firstDayOfYear) / 86400000;
        return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
    };

    const calculateEffectiveDays = useCallback(() => {
        let start = new Date(startDate);
        let end = new Date(endDate);
        let absencesByWeek = {};
        let holidayCount = 0;
    
        while (start <= end) {
            const dayOfWeek = start.getDay();
            // Create a local date string from start date to avoid timezone affecting the comparison
            const localDate = [start.getFullYear(), start.getMonth() + 1, start.getDate()].join('-').replace(/-(\d)(?!\d)/g, '-0$1');
            const isHoliday = holidays.some(holiday => holiday.date === localDate && holiday.observed_on_weekday === 1);
            if (isHoliday) {
                holidayCount++; // Increment if it's a holiday
            }
            if (dayOfWeek >= 1 && dayOfWeek <= 5 && !isHoliday) { // It's a weekday and not a holiday
                const weekOfYear = getWeekOfYear(start);
                absencesByWeek[weekOfYear] = (absencesByWeek[weekOfYear] || 0) + 8;
            }
            start.setDate(start.getDate() + 1);
        }
    
        setTotalHolidays(holidayCount);
        setAbsences(Object.keys(absencesByWeek).map(week => ({
            week_of_year: parseInt(week, 10),
            hours: absencesByWeek[week],
            year: new Date(startDate).getFullYear(),
            consultant_id: parseInt(consultantId, 10),
            absence_type: absenceType
        })));
    }, [startDate, endDate, holidays, consultantId, absenceType]);
    

    useEffect(() => {
        if (startDate && endDate) {
            calculateEffectiveDays();
        }
    }, [startDate, endDate, holidays, calculateEffectiveDays]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        setError('');

        try {
            await registerAbsence(absences);
            console.log('Absences submitted successfully');
            navigate('/'); // Navigate back to the overview page
        } catch (error) {
            setError(error.message);
            console.error('Failed to register absence', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            <h1>Registrera Ny Frånvaro</h1>
            <form onSubmit={handleSubmit}>
                <label>
                    Start Datum:
                    <input type="date" value={startDate} onChange={handleStartDateChange} />
                </label>
                <br />
                <label>
                    Till och med:
                    <input type="date" value={endDate} onChange={handleEndDateChange} />
                </label>
                <br />
                <label>
                    Frånvaro Typ:
                    <select value={absenceType} onChange={handleAbsenceTypeChange}>
                        <option value="Semester">Semester</option>
                        <option value="Föräldraledighet">Föräldraledighet</option>
                        <option value="Annat">Annat</option>
                    </select>
                </label>
                <br />
                <button type="submit" className="submit-btn" disabled={loading}>Rapportera</button>
                {loading && <p>Loading...</p>}
                {error && <p>Error: {error}</p>}
            </form>
            {absences.length > 0 && (
                <div>
                    <h2>Insikt</h2>
                    {absences.map((absence, index) => (
                        <p key={index}>Vecka {absence.week_of_year}: {absence.hours} timmar frånvaro</p>
                    ))}
                    <p>Röda veckodagar i perioden: {totalHolidays}</p>
                </div>
            )}
        </div>
    );
}
