import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import './Style/NewAbsence.css';
import { prepareWeeklyData } from './ConsultantCard';
import { fetchAbsencesForConsultant, fetchHolidays } from './Utils/Utils';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

async function addAllocationToDatabase(allocationData) {
    const response = await fetch(`${API_BASE_URL}/api/weekly_allocations`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(allocationData),
        credentials: 'include',
    });

    if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Failed to communicate with the API: ${errorText}`);
    }
    const data = await response.json();
    return data;
}

export default function NewAllocation() {
    const [customers, setCustomers] = useState([]);
    const [allocations, setAllocations] = useState([
        { customer_id: '', week_of_year: '', year: new Date().getFullYear(), hours_allocated: '' }
    ]);
    const [weeklyHours, setWeeklyHours] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const { consultantId } = useParams();

    useEffect(() => {
        async function fetchData() {
            try {
                const absences = await fetchAbsencesForConsultant(consultantId);
                const holidays = await fetchHolidays({credentials: 'include'});
                const data = prepareWeeklyData([], absences, holidays, new Date().getFullYear());
                setWeeklyHours(data);
            } catch (error) {
                setError(`Failed to fetch weekly hours: ${error.message}`);
            }
        }

        fetchData();
    }, [consultantId]);

    useEffect(() => {
        async function fetchCustomers() {
            try {
                const response = await fetch(`${API_BASE_URL}/api/customers`, {credentials: 'include'});
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const data = await response.json();
                const activeCustomers = data.customers.filter(customer => customer.is_active === true);
                setCustomers(activeCustomers);
            } catch (error) {
                setError(`Failed to load customers: ${error.message}`);
            }
        }

        fetchCustomers();
    }, []);


    const handleAddAllocation = () => {
        if (allocations.length > 0) {
            const lastAllocation = allocations[allocations.length - 1];
            setAllocations(allocations.concat({
                ...lastAllocation,
                week_of_year: parseInt(lastAllocation.week_of_year, 10) + 1, // Ensure it's treated as a number
            }));
        } else {
            // Fallback to empty values when there are no existing allocations
            setAllocations(allocations.concat({
                customer_id: '',
                week_of_year: '',
                year: new Date().getFullYear(),
                hours_allocated: ''
            }));
        }
    };    

    const handleInputChange = (index, event) => {
        const newAllocations = [...allocations];
        newAllocations[index][event.target.name] = event.target.value;
        setAllocations(newAllocations);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        setError('');
    
        // Convert input values to correct types as needed
        const validatedAllocations = allocations.map(allocation => ({
            ...allocation,
            week_of_year: parseInt(allocation.week_of_year, 10),
            hours_allocated: parseFloat(allocation.hours_allocated),
        }));
    
        try {
            const results = await Promise.all(validatedAllocations.map(allocation => {
                const fullAllocationData = { ...allocation, consultant_id: consultantId };
                return addAllocationToDatabase(fullAllocationData);
            }));
    
            console.log('All allocations added:', results);
            navigate('/'); // Navigate back to the overview
        } catch (error) {
            setError(`${error.message}`);
            console.error('Allocation error:', error.message); // Detailed log in the console
        } finally {
            setLoading(false);
        }
    };
    

    return (
        <div className="container">
            <form onSubmit={handleSubmit} className="form">
                {allocations.map((allocation, index) => (
                    <div key={index} className="allocation-form">
                        <select
                            name="customer_id"
                            value={allocation.customer_id}
                            onChange={e => handleInputChange(index, e)}
                            required
                        >
                            <option value="">Kund</option>
                            {customers.map(customer => (
                                <option key={customer.customer_id} value={customer.customer_id}>
                                    {customer.customer_name}
                                </option>
                            ))}
                        </select>
                        <input
                            type="number"
                            name="year"
                            placeholder="År"
                            value={allocation.year}
                            onChange={e => handleInputChange(index, e)}
                            required
                        />
                        <input
                            type="number"
                            name="week_of_year"
                            placeholder="Vecka"
                            value={allocation.week_of_year}
                            onChange={e => handleInputChange(index, e)}
                            required
                        />
                        <input
                            type="number"
                            name="hours_allocated"
                            placeholder="Timmar"
                            value={allocation.hours_allocated}
                            onChange={e => handleInputChange(index, e)}
                            required
                        />
                        {index === allocations.length - 1 && (
                    <button type="button" className="add-allocation-btn" onClick={handleAddAllocation}>
                        +
                    </button>
                )}
                    </div>
                ))}
                <button type="submit" className="submit-btn" disabled={loading}>Skicka in</button>
                {loading && <p>Loading...</p>}
            {error && <p>{error}</p>}
            </form>   
        </div>
    );
}