import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getNumberOfWeeksInYear } from './Utils/Utils'; // Adjust the path as needed

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export default function AbsenceDetails() {
    const [consultantName, setConsultantName] = useState('');
    const [absences, setAbsences] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { consultantId, year, weekNumber } = useParams();
    const navigate = useNavigate();

    const maxWeeks = getNumberOfWeeksInYear(parseInt(year)); // Get the number of weeks in the given year

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${API_BASE_URL}/api/absence-details/${consultantId}/${year}/${weekNumber}`, {credentials: 'include'});
                if (!response.ok) {
                    throw new Error(`HTTP status ${response.status}`);
                }
                const data = await response.json();
                setConsultantName(data.consultantName || 'Unknown Consultant');
                setAbsences(data.absences);
                console.log("Fetched absences:", data.absences);
            } catch (error) {
                setError("Failed to fetch absence details: " + error.message);
                console.error(error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [consultantId, year, weekNumber]);

    const handleHoursChange = (index, newHours) => {
        const updatedAbsences = [...absences];
        updatedAbsences[index].hours = newHours;
        setAbsences(updatedAbsences);
    };

    const updateHours = async () => {
        try {
            const updates = absences.map(absence => ({
                absence_id: absence.absence_id,
                hours: absence.hours
            }));
            console.log("Sending updates:", JSON.stringify(updates));
            const response = await fetch(`${API_BASE_URL}/api/bulk-update-absences`, {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(updates),
                credentials: 'include',
            });
            if (!response.ok) {
                throw new Error(`HTTP status ${response.status}`);
            }
            navigate('/absence'); // Navigate back to the previous page
        } catch (error) {
            alert("Failed to update hours: " + error.message);
            console.error("Detailed error:", error);
        }
    };

    const deleteAbsence = async (absenceId) => {
        if (!window.confirm("Bekräfta borttagning")) {
            return;
        }

        try {
            const response = await fetch(`${API_BASE_URL}/api/absence/${absenceId}`, {
                method: 'DELETE',
                credentials: 'include',
            });
            if (!response.ok) {
                throw new Error(`HTTP status ${response.status}`);
            }
            setAbsences(absences.filter(absence => absence.absence_id !== absenceId));
        } catch (error) {
            alert("Failed to delete absence: " + error.message);
            console.error("Detailed error:", error);
        }
    };

    const goToPreviousWeek = () => {
        const previousWeek = Math.max(1, parseInt(weekNumber) - 1); // Ensure the week number doesn't go below 1
        navigate(`/absence-details/${consultantId}/${year}/${previousWeek}`); // Correct: Client-side navigation to the previous week
    };
    
    const goToNextWeek = () => {
        const nextWeek = Math.min(maxWeeks, parseInt(weekNumber) + 1); // Use maxWeeks to limit the week number
        navigate(`/absence-details/${consultantId}/${year}/${nextWeek}`); // Correct: Client-side navigation to the next week
    };

    const handleCancel = () => {
        navigate('/absence'); // Correct: Client-side navigation to /absence view
    };

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;

    return (
        <div>
            <h2>Konsult: {consultantName}</h2>
            <h1><button onClick={goToPreviousWeek} disabled={weekNumber <= 1}>&larr;</button>   Vecka {weekNumber}, {year}  <button onClick={goToNextWeek} disabled={weekNumber >= maxWeeks}>&rarr;</button></h1>
            {absences.length > 0 ? (
                <ul>
                    {absences.map((absence, index) => (
                        <p key={index}>
                            Typ: {absence.absence_type} - Timmar:
                            <input id="input-hours"
                                type="number"
                                value={absence.hours}
                                onChange={(e) => handleHoursChange(index, e.target.value)}
                            />
                            <button onClick={() => deleteAbsence(absence.absence_id)} className="delete-btn">X</button>
                        </p>
                    ))}
                </ul>
            ) : (
                <p>Inga frånvarotimmar rapporterade.</p>
            )}
            <button onClick={updateHours} className="submit-btn">Spara</button>
            <button onClick={handleCancel} className="submit-btn" id="cancel-button">Avbryt</button>
        </div>
    );
}
