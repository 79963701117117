import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import '../App.css';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

async function addCustomerToDatabase(customerName) {
    try {
        const response = await fetch(`${API_BASE_URL}/api/customers`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ customer_name: customerName }),
            credentials: 'include',
        });
        if (!response.ok) {
            const errorText = await response.text();
            console.error("Failed response text:", errorText);
            throw new Error(`Failed to communicate with the API: ${response.statusText}`);
        }
        const data = await response.json();
        return data;
    } catch (error) {
        console.error("Error in addCustomerToDatabase:", error);
        throw error;
    }
}

export default function NewCustomer() {
    const [customerName, setCustomerName] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        setError('');

        try {
            const data = await addCustomerToDatabase(customerName);
            console.log(`Added customer with ID: ${data.customer_id}`);
            navigate('/Customer'); // Navigate back to the customer page
        } catch (error) {
            setError(error.message);
            console.error('Failed to add customer', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="container">
            <form onSubmit={handleSubmit} className="form">
                <div className="input-container">
                    <label htmlFor="customerName">Kund:</label>
                    <input
                        type="text"
                        id="customerName"
                        value={customerName}
                        onChange={(e) => setCustomerName(e.target.value)}
                        required
                    />
                </div>
                <button className="button-37" type="submit" disabled={loading}>Lägg till kund</button>
                {loading && <p>Loading...</p>}
                {error && <p>Error: {error}</p>}
            </form>
        </div>
    );
}