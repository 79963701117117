import React from 'react';
import { BrowserRouter as Router, Route, Link, Routes, Navigate }
    from "react-router-dom";
import './App.css';

// Import  pages
import Absence from "./Components/Absence"
import Customer from "./Components/Customer"
import Overview from "./Components/Overview"
import Kapacitet from "./Components/Kapacitet"
import NewConsultant from "./Components/NewConsultant";
import NewAllocation from "./Components/NewAllocation";
import NewCustomer from "./Components/NewCustomer";
import NewAbsence from './Components/NewAbsence';
import ConsultantHeatmap from './Components/ConsultantHeatmap'
import AllocationDetails from './Components/AllocationDetails'
import AbsenceHeatmap from './Components/AbsenceHeatmap';
import AbsenceDetails from './Components/AbsenceDetails';
import LogInPage from './Components/LogInPage'

function PrivateRoute({ children }) {
  const isAuthenticated = sessionStorage.getItem('isAuthenticated');
  return isAuthenticated ? children : <Navigate to="/login" />;
}

export default function App() {
  return (
      <div className="App">
          <Router>
              <div className="App">
                  <nav style={{ display: 'flex', justifyContent: 'space-around', backgroundColor: '#f0f0f0', padding: '1rem' }}>
                      <Link to="/">Översikt</Link>
                      <Link to="Absence">Frånvaro</Link>
                      <Link to="Customer">Kunder</Link>
                      <Link to="Kapacitet">Kapacitet</Link>
                  </nav>
                  <Routes>
                      <Route path="/login" element={<LogInPage />} />
                      <Route path="/" element={<PrivateRoute><Overview /></PrivateRoute>} />
                      <Route path="Absence" element={<PrivateRoute><Absence /></PrivateRoute>} />
                      <Route path="Customer" element={<PrivateRoute><Customer /></PrivateRoute>} />
                      <Route path="Kapacitet" element={<PrivateRoute><Kapacitet /></PrivateRoute>} />
                      <Route path="NewConsultant" element={<PrivateRoute><NewConsultant /></PrivateRoute>} />
                      <Route path="NewAllocation" element={<PrivateRoute><NewAllocation /></PrivateRoute>} />
                      <Route path="NewAllocation/:consultantId" element={<PrivateRoute><NewAllocation /></PrivateRoute>} />
                      <Route path="NewCustomer" element={<PrivateRoute><NewCustomer /></PrivateRoute>} />
                      <Route path="NewAbsence/:consultantId" element={<PrivateRoute><NewAbsence /></PrivateRoute>} />
                      <Route path="ConsultantHeatmap" element={<PrivateRoute><ConsultantHeatmap /></PrivateRoute>} />
                      <Route path="/allocation-details/:consultantId/:year/:weekNumber" element={<PrivateRoute><AllocationDetails /></PrivateRoute>} />
                      <Route path="AbsenceHeatmap" element={<PrivateRoute><AbsenceHeatmap /></PrivateRoute>} />
                      <Route path="/absence-details/:consultantId/:year/:weekNumber" element={<PrivateRoute><AbsenceDetails /></PrivateRoute>} />
                  </Routes>
              </div>
          </Router>
      </div>
  );
}

//export default App;
