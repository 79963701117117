import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import { getCurrentWeek } from './Kapacitet';
import { fetchAbsencesForConsultant, fetchHolidays, getBarColor } from './Utils/Utils';
import './Style/ConsultantCard.css';

// Helper function to prepare weekly data
export const prepareWeeklyData = (allocations, absences, holidays, selectedYear) => {
    // Calculate the correct number of weeks in the selected year
    const startDate = new Date(selectedYear, 0, 1); // January 1st of selected year
    const endDate = new Date(selectedYear, 11, 31); // December 31st of selected year
    const startDayOfWeek = startDate.getDay();
    const endDayOfWeek = endDate.getDay();
    const totalDays = (endDate - startDate) / (1000 * 3600 * 24) + 1; // Total days in the year
    let numWeeks = Math.floor(totalDays / 7);
    numWeeks += (startDayOfWeek > 0 && endDayOfWeek < 6) ? 1 : 0; // Adjust for partial weeks at start and end of the year

    let weeklyHours = Array.from({ length: numWeeks }, () => ({ allocated: 0, maxAvailable: 40 }));

    absences.forEach(absence => {
        if (absence.year === selectedYear) {
            const weekIndex = Math.min(absence.week_of_year - 1, numWeeks - 1);
            weeklyHours[weekIndex].maxAvailable -= absence.hours;
        }
    });

    // Filter holidays by selected year and process them
    holidays.forEach(holiday => {
        const holidayDate = new Date(holiday.date);
        if (holidayDate.getFullYear() === selectedYear) {
            const weekIndex = Math.min(holiday.week_of_year - 1, numWeeks - 1);
            weeklyHours[weekIndex].maxAvailable -= 8; // Assuming 8 hours for a holiday
        }
    });

    allocations.forEach(allocation => {
        if (allocation.year === selectedYear) {
            const weekIndex = Math.min(allocation.week_of_year - 1, numWeeks - 1);
            /*console.log('Before parsing:', allocation.hours_allocated);*/
            // Ensure hours_allocated is treated as a number
            weeklyHours[weekIndex].allocated += parseFloat(allocation.hours_allocated);
            /*console.log('After parsing:', weeklyHours[weekIndex].allocated);*/
        }
    });

    return weeklyHours;
};

export default function ConsultantCard({ consultant, allocations, selectedConsultantId }) {
    const navigate = useNavigate();
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
    const [weeklyHours, setWeeklyHours] = useState([]);
    const scrollRef = useRef(null); // Reference for the scrollable container

    useEffect(() => {
        const fetchData = async () => {
            const absences = await fetchAbsencesForConsultant(consultant.consultant_id);
            const holidays = await fetchHolidays();
            const data = prepareWeeklyData(allocations, absences, holidays, selectedYear);
            /*console.log('Fetched absences:', absences); // Log absences
        console.log('Fetched holidays:', holidays); // Log holidays
        console.log('Prepared weekly data:', data); // Log prepared weekly data*/
            setWeeklyHours(data);
        };

        fetchData();
    }, [consultant.consultant_id, allocations, selectedYear]);

    useEffect(() => {
        if (weeklyHours.length > 0) {
            const currentWeek = getCurrentWeek();
            const barWidth = 80; // The width of each bar
            const scrollDistance = (currentWeek - 1) * barWidth; // Calculate the scroll distance
            if (scrollRef.current) {
                scrollRef.current.scrollLeft = scrollDistance; // Scroll the container
            }
        }
    }, [weeklyHours]);

    // Function to handle year change
    const handleYearChange = (event) => {
        setSelectedYear(parseInt(event.target.value, 10));
    };

    const handleAllocationClick = () => {
        navigate(`/NewAllocation/${consultant.consultant_id}`);
    };

    const handleAbsenceClick = () => {
        navigate(`/NewAbsence/${consultant.consultant_id}`);
    };

    const handleBarClick = (weekNumber) => {
        navigate(`/allocation-details/${consultant.consultant_id}/${selectedYear}/${weekNumber}`);
    };

    const handleDeleteClick = async () => {
        if (window.confirm('Ta bort konsult och alla relaterade poster')) {
            try {
                const response = await fetch(`/api/consultant/${consultant.consultant_id}`, {
                    method: 'DELETE'
                });
                if (response.ok) {
                    alert('Konsult och relaterade poster borttaget');
                    navigate('/'); // Redirect to home or another appropriate page
                } else {
                    const errorData = await response.json();
                    alert(`Failed to delete consultant: ${errorData.error}`);
                }
            } catch (error) {
                console.error('Error deleting consultant:', error);
                alert('An error occurred while deleting the consultant.');
            }
        }
    };

    return (
        <div className="consultant-card">
            <div className="consultant-info">
                <h2>{consultant.consultant_name}</h2>
                <select value={selectedYear} onChange={handleYearChange}>
                    <option value={2023}>2023</option>
                    <option value={2024}>2024</option>
                    <option value={2025}>2025</option>
                    {/* More years can be added dynamically if needed */}
                </select>
                <br></br>
                <button className="add-allocation-btn" onClick={handleAllocationClick}>Planera</button>
                <button className="add-absence-btn" onClick={handleAbsenceClick}>Frånvaro</button>
                <br></br>
                <button className="delete-consultant-btn" onClick={handleDeleteClick}>Ta bort</button>
            </div>
            <div className="consultant-hours-bar" ref={scrollRef}>
                {weeklyHours.map((weekData, index) => (
                    <div
                        key={index}
                        className="bar"
                        style={{
                            height: `${Math.min(weekData.allocated, 40) * 5}px`,
                            minHeight: '15px',
                            backgroundColor: getBarColor(weekData.allocated, weekData.maxAvailable)
                        }}
                        onClick={() => handleBarClick(index + 1)} // Added onClick handler here
                    >
                        V{index + 1}: {weekData.allocated}h/{weekData.maxAvailable}h
                    </div>
                ))}
            </div>
        </div>
    );
}
