import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import '../App.css'

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

async function addConsultantToDatabase(name, navigate) {
    try {
        const response = await fetch(`${API_BASE_URL}/api/consultants`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ consultant_name: name }),
            credentials: 'include',
        });
        if (!response.ok) {
            const errorText = await response.text(); // Get text response to see what went wrong
            console.error("Failed response text:", errorText);
            throw new Error(`Failed to communicate with the API: ${response.statusText}`);
        }
        const data = await response.json();
        return data;
    } catch (error) {
        console.error("Error in addConsultantToDatabase:", error);
        throw error; // Re-throw to be caught by the try-catch in handleSubmit
    }
}


export default function NewConsultant() {
    const [consultantName, setConsultantName] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        setError('');

        try {
            const data = await addConsultantToDatabase(consultantName, navigate);
            console.log(`Added consultant with ID: ${data.consultant_id}`);
            navigate('/'); // Navigate back to the overview page
        } catch (error) {
            setError(error.message);
            console.error('Failed to add consultant', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="container">
            <form onSubmit={handleSubmit} className="form">
                <div className="input-container">
                    <label htmlFor="consultantName">Namn:</label>
                    <input
                        type="text"
                        id="consultantName"
                        value={consultantName}
                        onChange={(e) => setConsultantName(e.target.value)}
                        required
                    />
                </div>
                <button className="button-37" type="submit" disabled={loading}>Lägg till</button>
                {loading && <p>Loading...</p>}
                {error && <p>Error: {error}</p>}
            </form>
        </div>
    );
}


